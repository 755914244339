import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { RegEchoVariantDto } from '@model/undersokningsvarianter/reg-echo-variant-dto';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UndersokningsVariantService } from '@core/services/undersokningsvariant.service';
import { VariablesService } from '@core/services/variabler.service';
import { catchError } from 'rxjs/operators';
import { AlertMessageItem, AlertMessageType } from '../../../../model/alert-message-item';
import { VariablesByGroupsDto } from '@model/undersokningsvarianter/variables-by-groups-dto';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AddExaminationTypeModalService } from '../../components/add-examinationtype-modal/add-examinationtype-modal.component';
import { ToastrService } from 'ngx-toastr';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'manage-examination-variants',
  templateUrl: './manage-examination-variants.component.html',
  styleUrls: ['./manage-examination-variants.component.scss']
})
export class ManageExaminationVariantsComponent implements OnInit {
  public loading = new BehaviorSubject<boolean>(false);
  //Ikoner
  faCalendar = faCalendar;
  selectedExType: Observable<RegEchoVariantDto>;
  submitted = false;
  variablesByGroupType1: VariablesByGroupsDto[] = null!;
  variablesByGroupType2: VariablesByGroupsDto[] = null!;
  examinationTypesList: Observable<RegEchoVariantDto[]> = null!;
  $subscriptions: Subscription[] = [];
  selectedExaminationTypeId: number = 0;
  constructor(
    private dataService: UndersokningsVariantService,
    private variableService: VariablesService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
    private addExaminationTypeModal: AddExaminationTypeModalService,
    private toastr: ToastrService

  ) { }
  public vyType: number = 1;
  public typeSelected: boolean;
  public createNewExaminationType: boolean;
  public isEmptyType = false;
  private typeUrl: string;
  @Output() onSubmit = new EventEmitter<FormGroup>();
  public get examinationTypeForm(): FormGroup { return this._examinationTypeForm; }
  get id() { return this.examinationTypeForm.get('id'); }
  get variantName() { return this.examinationTypeForm.get('variantName'); }
  get variantDescription() { return this.examinationTypeForm.get('variantDescription'); }
  get validFrom() { return this.examinationTypeForm.get('validFrom') }
  get validTo() { return this.examinationTypeForm.get('validTo'); }
  get typeActiveFrom() { return this.examinationTypeForm.get('typeActiveFrom'); }
  get typeActiveTo() { return this.examinationTypeForm.get('typeActiveTo'); }
  get inaktiv() { return this.examinationTypeForm.get('inaktiv'); }
  get examinationType() { return this._examinationTypeForm.get('examinationType'); }
  private _examinationTypeForm: FormGroup = new FormGroup({
    examinationType: new FormControl(0),
    id: new FormControl(),
    variantName: new FormControl('', [Validators.required]),
    variantDescription: new FormControl('', [Validators.required]),
    validFrom: new FormControl(),
    validTo: new FormControl(),
    typeActiveFrom: new FormControl(true),
    typeActiveTo: new FormControl(true),
    inaktiv: new FormControl(false)
  });
  //////////
  ///
  ngOnInit(): void {
    this.getTypes();
    this.fetchVariables();
    this.typeSelected = false;
  }
  //////////
  ///
  ngOnDestroy(): void {
    this.$subscriptions.forEach(_ => _.unsubscribe());
  }
  //////////
  ///
 
  getTypes() {
    //this.examinationTypesList$ = this.dataService.examinationTypesList$.pipe(catchError(err => {
    //   //this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, err));
    //   return EMPTY;
    //}));
    this.examinationTypesList = this.dataService.getExaminationTypes(true);
    console.table("Typer" + this.examinationTypesList);
    this.examinationTypeForm.get("examinationType").patchValue(this.selectedExaminationTypeId);

  }
  //////////
  ///
  fetchVariables() {
    //Ladda bägge listvyerna direkt och toggla sedan mellan dessa?
    this.variableService.getAllEchoVariablesByGroup(1, "", "").subscribe(
      (res: VariablesByGroupsDto[]) => {
        this.variablesByGroupType1 = res.filter(f => f.echoGroupTypeId == 1);
        res.filter(f => f.echoGroupTypeId == 1).forEach((group) => {
          group.sysEchoVariables.forEach((variable) => {
            this._examinationTypeForm.addControl("var_" + variable.id.toString(), new FormControl());
          });
        });
      });
    this.variableService.getAllEchoVariablesByGroup(2, "", "").subscribe(
      (res: VariablesByGroupsDto[]) => {
        this.variablesByGroupType2 = res.filter(f => f.echoGroupTypeId == 2);
        res.filter(f => f.echoGroupTypeId == 2).forEach((group) => {
          group.sysEchoVariables.forEach((variable) => {
            this._examinationTypeForm.addControl("var_" + variable.id.toString(), new FormControl());
          });
        });
      });
  }
  //////////
  ///
  public getDataFromEchoType(id: number) {

    this.examinationTypeForm.reset();
    this.selectedExaminationTypeId = id;
    this.dataService.getEchoVariant(id).subscribe((prevUppg) => {

      this._examinationTypeForm.patchValue({
        id: id,
        variantName: prevUppg.variantName,
        variantDescription: prevUppg.variantDescription,
        validFrom: prevUppg.validFrom != null ? this.formatBack(prevUppg.validFrom) : "",
        validTo: prevUppg.validFrom != null ? this.formatBack(prevUppg.validTo) : "",
        inaktiv: !prevUppg.active
      });
      // Markerar valda variabler från listan med id:n i strängen valdaVarJson.
      if (prevUppg.valdaVarJson) {
        console.log(prevUppg.valdaVarJson);
        let varIds = prevUppg.valdaVarJson.split(",");
        for (var i = 0; i < varIds.length; i++) {
          //TODO: Är detta rätt eller blir det för krångligt? Ska man istället spara både grupp och variabel på varianten för att kunna få ut gruppen här?
          this.variableService.getVariable(Number(varIds[i])).subscribe((data) => {
            data.sysEchoGroupIds.forEach(group => {
              this._examinationTypeForm.get("var_" + data.id).patchValue(true);
            });
          });
        }
      }
      if (prevUppg.validFrom != null) {
        this._examinationTypeForm.get('typeActiveFrom').patchValue(true);
      }
      if (prevUppg.validTo != null) {
        this._examinationTypeForm.get('typeActiveTo').patchValue(true);
      }
    });
    this.typeSelected = true;
    this._examinationTypeForm.get('examinationType').patchValue(id);
  }
  //////////
  ///
  showVyType(event: Event) {
    this.vyType = Number((<HTMLInputElement>event.target).value);
  }
  //////////
  ///
  submitForm() {
    this.submitted = true;
    if (!this.examinationTypeForm.valid) {
      //Något felaktigt i formuläret, visa alla felmeddelanden
      this.examinationTypeForm.markAllAsTouched();
      this.toastr.error("Det gick inte att spara.", "", { positionClass: 'toast-top-center' });
      return;
    }
    
    if (this.examinationTypeForm.valid && this.selectedExaminationTypeId > 0) {
      let echovars: Array<string> = [];
      this.variablesByGroupType1.forEach(group => {
        group.sysEchoVariables.forEach(variable => {
          if (this.examinationTypeForm.get(["var_" + variable.id.toString()]).value == true && !echovars.includes(variable.id.toString()))
            echovars.push(variable.id.toString());
        })
      });
      let u: RegEchoVariantDto = {
        id: this.selectedExaminationTypeId,
        variantName: this.examinationTypeForm.get("variantName")?.value ?? "",
        variantDescription: this.examinationTypeForm.get("variantDescription")?.value ?? "",
        kompetenskrav: null, 
        validFrom: this.format(this.examinationTypeForm.get("validFrom")?.value) ?? null,
        validTo: this.format(this.examinationTypeForm.get("validTo")?.value) ?? null,
        valdaVarJson: echovars.toString(),
        active: this.examinationTypeForm.get("inaktiv")?.value == true ? false : true
      };
      console.log(u);
      this.dataService.editVariant(u).subscribe(newVar => {
        window.scrollTo(0, 0);
        this.toastr.success(u.variantName + " har ändrats.", "", { positionClass: 'toast-top-center' });
      }, error => {
        console.error(error);
      })
    }
  }
  openAddExaminationTypeModal() {
    const mRef = this.addExaminationTypeModal.show();
    this.typeSelected = true;
    this.$subscriptions.push(
      mRef.subscribe({
        next: (variant: any) => {
          console.table(variant);
          this.selectedExaminationTypeId = variant.id;
          this.typeSelected = true;
          this.examinationTypeForm.reset();
          this.examinationTypeForm.get("id").patchValue(variant.id);
          this.examinationTypeForm.get("variantName").patchValue(variant.variantName);
          this.examinationTypeForm.get("variantDescription").patchValue(variant.variantDescription);
          if (variant.validFrom != null) {
            this.examinationTypeForm.get('typeActiveFrom').patchValue(true);
            this.examinationTypeForm.get("validFrom").patchValue(this.formatBack(variant.validFrom));
          }
          if (variant.validTo != null) {
            this.examinationTypeForm.get('typeActiveTo').patchValue(true);
            this.examinationTypeForm.get("validTo").patchValue(this.formatBack(variant.validTo));
          }
            //validFrom: variant.variant != null ? this.formatBack(variant.validFrom) : "",
            //validTo: variant.validFrom != null ? this.formatBack(variant.validTo) : ""
            this.toastr.success(variant.variantName + " har lagts till", "", { positionClass: 'toast-top-center' });
          this.getTypes();
          this.examinationTypeForm.get("examinationType").patchValue(variant.id);
          //Uppdatera värden som blivit ändrade i modalen
          //data.map(_ => {

          //});

        }
      })
    );
  }

  public currentSavedVariant: RegEchoVariantDto;
  public getCurrentSavedVariant(variant: RegEchoVariantDto) {
    this.currentSavedVariant = variant;
    this.getTypes();
    this._examinationTypeForm.get('examinationType').patchValue(variant.id);
    this.getDataFromEchoType(variant.id);
  }


  private format(date: NgbDateStruct): Date {
    return date ? new Date(Date.UTC(date.year, date.month - 1, date.day)) : null
  }
  private formatBack(date: Date) {
    let formatted = new Date(date);
    return { year: formatted.getFullYear(), month: formatted.getMonth() + 1, day: formatted.getDate() };
  }
}


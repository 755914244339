import { AfterContentChecked, AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ViewEchoVariableDto } from '../../../../model/undersokningsvarianter/view-echo-variable-dto';
import { PatientRemissUndersokning } from '../../../../core/services/undersokning.service';
import { Observable, Subscription } from 'rxjs';
import { faChevronDown, faChevronUp, faBars } from "@fortawesome/free-solid-svg-icons";

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'result-table',
  templateUrl: './result-table.component.html',
  styleUrls: ['./result-table.component.scss']
})
export class ResultTableComponent implements OnInit, AfterContentChecked {
  constructor(
    private changeDetector: ChangeDetectorRef
  ) { }
  //Ikoner
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faBars = faBars;


  $subscriptions: Subscription[] = [];
  @Input() variables: ViewEchoVariableDto[];
  @Input() undersokning: PatientRemissUndersokning;
  showTable: boolean = false;

  tables: TableData[] = [
    {
      id: 1, headers: [
        { id: 1, description: "Dimensioner (2D, 3D, MM)", colspan: 2 },
        { id: 3, description: "Mätt", colspan: 1 },
        { id: 4, description: "idx/m2", colspan: 1 },
        { id: 5, description: "Ref", colspan: 1, width: "10%" }],
      groupedRows: [{
        id: 1,
        description: "LV",
        isVertical: true,
        rows:
          [{ id: 1, rowDescription: "diameter dias/syst", variableIds: [7, 8], canOpen: true  },
            { id: 2, rowDescription: "septum/bakvägg", variableIds: [6, 9], canOpen: true }]
      },
        {
          id: 2,
          description: "LA",
          isVertical: false,
          rows:
            [{ id: 1, rowDescription: "volym", variableIds: [45], showChildren: false },
              { id: 1, rowDescription: "", variableIds: [199], showChildren: false }]
        },
        {
          id: 3,
          description: "RV",
          isVertical: false,
          rows:
            [{ id: 1, rowDescription: "diameter (RVD 1)", variableIds: [60], showChildren: false }]
        },
        {
          id: 4,
          description: "RA",
          isVertical: false,
          rows:
            [{ id: 1, rowDescription: "area", variableIds: [64], showChildren: false }]
        },
        {
          id: 5,
          description: "Ao",
          isVertical: true,
          rows:
            [{ id: 1, rowDescription: "Sinus Valsalva", variableIds: [1], showChildren: false, canOpen: false },
              { id: 2, rowDescription: "Ascendens", variableIds: [3], showChildren: false, canOpen: false }]
        }
      ]
    },
    {
      id: 2, headers: [
        { id: 1, description: "Doppler", colspan: 1 },
        { id: 2, description: "LVOT", colspan: 1, columnIsValue: true },
        { id: 3, description: "AV1", colspan: 1, columnIsValue: true },
        { id: 4, description: "MV1", colspan: 1, columnIsValue: true },
        { id: 5, description: "PV1", colspan: 1, columnIsValue: true },
        { id: 6, description: "TV1", colspan: 1, columnIsValue: true }],
      showUnitWithNames: true,
      showMultipleVariableValues: true,
      groupedRows: [{
        id: 1,
        description: "Vmax",
        isVertical: true,
        rows:
          [{
            id: 1, rowDescription: "", variableIds: [null, null, null, null, null], canOpen: false, showChildren: false
          }]
      },
      {
        id: 2,
        description: "p",
        isVertical: false,
        rows:
          [{ id: 1, rowDescription: "", variableIds: [null, null, null, null, null], showChildren: false }]
      },
      {
        id: 3,
        description: "VTI",
        isVertical: false,
        rows:
          [{ id: 1, rowDescription: "", variableIds: [41, null, null, null, null], showChildren: false }]
      },
      {
        id: 4,
        description: "Area",
        isVertical: false,
        rows:
          [{ id: 1, rowDescription: "", variableIds: [null, null, null, null, null], showChildren: false }]
      },
      {
        id: 5,
        description: "Insufficience",
        isVertical: false,
        rows:
          [{ id: 1, rowDescription: "", variableIds: [null, null, null, null, null], showChildren: false, canOpen: false }]
        }
      ], footer: [{
        id: 1,
        colspan: 3,
        variableIds: [null],
        description: "1 = liten 2 = måttlig, 3 = uttalad"
      },
        {
          id: 2,
          colspan: 4,
          variableIds: [32],
          description: ""
        }]
    },
    {
      id: 3, headers: [
        { id: 1, description: "Systolisk kammarfunktion", colspan: 2 },
        { id: 3, description: "Mätt", colspan: 1 },
        { id: 4, description: "Per m2", colspan: 1 },
        { id: 5, description: "Ref", colspan: 1 }
      ],
      groupedRows: [{
        id: 1,
        description: "LV",
        isVertical: true,
        rows:
          [
            //{ id: 1, rowDescription: "Av-planrörlighet (MAPSE)", variableIds: [], canOpen: true },
            //{ id: 2, rowDescription: "Ejektionsfraktion (EF)", variableIds: [], showChildren: false },
            { id: 3, rowDescription: "Global long. strain (GLS)", variableIds: [46], showChildren: false }
          ]
      },
      {
        id: 2,
        description: "RV",
        isVertical: true,
        rows:
          [
            { id: 1, rowDescription: "Av-planrörlighet (TAPSE)", variableIds: [62], canOpen: false },
/*            { id: 2, rowDescription: "Peak systolic Velocity(s´))", variableIds: [], showChildren: false },*/
            { id: 3, rowDescription: "RVOT SE", variableIds: [61], showChildren: false }
          ]
      }]
    },
    {
      id: 4, headers: [
        { id: 1, description: "Lungkretsloppet", colspan: 2 },
        { id: 3, description: "Mätt", colspan: 1 },
        { id: 4, description: "Ref", colspan: 1 }
      ],
      groupedRows: [{
        id: 1,
        description: "",
        isVertical: false,
        rows:
          [{
            id: 1, rowDescription: "", variableIds: [83], canOpen: false, isBold: true
          }]
      },
      {
        id: 2,
        description: "",
        isVertical: false,
        rows:
          [{ id: 1, rowDescription: "", variableIds: [16], showChildren: false, isBold: true }]
      }]
    }
  ];

  ngOnInit(): void {
    /*Skriv ut graderingstyp 6 startvärde eller 9(större eller lika med)?*/
  }
  //////////
  ///
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  getData(showTable: boolean) {

    this.tables.map(table => {
      table.footer?.map(footer => {
          footer.variables = this.variables.filter(variable => footer.variableIds.includes(variable.id));
          console.log(footer.variables);
      });
      table.groupedRows.map(groupedRow => {
        groupedRow.totalVariables = groupedRow.rows.flatMap(row => row.variableIds).length + groupedRow.rows.length;

        //if (!table.showMultipleVariableValues) {
          groupedRow.rows.map(row => {
            row.variables = this.variables.filter(variable => row.variableIds.includes(variable.id));
            row.variables.some(variable => {
              if(variable.measurementMethods?.some(method => method.value !== ""))
                row.hasMethodValues = true;
            });
          });
        /*}*/
        if (table.showMultipleVariableValues) {
          groupedRow.rows.map(row => {
            row.variables = row.variableIds.map(id => {
              if (id === null) {
                return {
                  id: null,
                  shortName: "",
                  short: "",
                  currentValue: ""
                } as ViewEchoVariableDto;
              } else {
                return this.variables.find(variable => variable.id === id) || {
                  id: null,
                  shortName: "",
                  short: "",
                  currentValue: ""
                } as ViewEchoVariableDto;
              }
            });
          });
          //groupedRow.variables = allaVariablerGruppen.map(variable => variable.id == null ? new ViewEchoVariableDto() : variable);
          //groupedRow.variables.forEach(_ => { _.id = null, _.shortName = "", _.short = "", _.currentValue = "" });
        }
      });

    });
  }
  showChild(id: number) {
    const row = this.tables.flatMap(table => table.groupedRows.flatMap(groupedRow => groupedRow.rows)).find(row => row.id === id);
    if (row) {
      row.showChildren = !row.showChildren;
    }
  }
}
export class TableData {
  id: number;
  headers: Header[];
  groupedRows: GroupedRow[];
  showUnitWithNames?: boolean | false;
  showMultipleVariableValues?: boolean | false;
  footer?: Footer[] | [];
}
export class Header {
  id: number;
  colspan?: number | 1;
  columnIsValue?: boolean | false;
  description: string;
  width?: string | '';
}
export class GroupedRow {
  id: number;
  description: string;
  rows: Row[];
  totalVariables?: number | 0;
  isVertical?: boolean | false;
  showMethods?: boolean | false;
  variables?: ViewEchoVariableDto[];
}
export class Row {
  id: number;
  rowDescription?: string;
  variableIds?: number[];
  showChildren?: boolean | false;
  variables?: ViewEchoVariableDto[];
  mattValue?: string | "";
  refValue?: string | "";
  canOpen?: boolean | false;
  isBold?: boolean | false;
  hasMethodValues?: boolean | false;

}
export class Footer {
  id: number;
  colspan?: number | 1;
  description?: string | "";
  variableIds?: number[];
  variables?: ViewEchoVariableDto[];
}

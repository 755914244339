import { Component, Injectable, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { Test_Undersokningsvarden, Test_UndersokningsvardenDto, UlResult, UndersokningService } from '../../../../core/services/undersokning.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'add-test-undesokningsvarden-modal',
  templateUrl: './add-test-undesokningsvarden-modal.component.html',
  styleUrls: ['./add-test-undesokningsvarden-modal.component.scss']
})
export class AddTestUndesokningsvardenModalComponent implements OnInit {
  id: number = 0;
  name: string = "";
  ulValues: UlResult[];
  submitted: boolean = false;
  constructor(
    public translate: TranslateService,
    private modalRef: NgbActiveModal,
    private service: UndersokningService,
    private toastr: ToastrService
  ) { }

  private _editForm: FormGroup = new FormGroup({
    namn: new FormControl("", { validators: [Validators.required, this.validatorNameNotExists()], updateOn: 'blur' }),
  });
  get editForm(): FormGroup { return this._editForm; }
  get namn() { return this.editForm.get("namn"); }
  //get invalidNamn() { return this.fieldIsInvalid("namn"); }
  //get errorNamn() { return this.fieldHasError("namn"); }
  ////////////
  ///
  private fieldIsInvalid(field: string) {
    return this.editForm.controls[field].invalid && this.editForm.controls[field].touched;
  }
  //////////
  ///
  private fieldHasError(field: string) {
    return this.editForm.controls[field].errors;
  }
  //////////
  ///
  ngOnInit(): void {
    //Om redigering av befintlig
    if (this.id > 0)
      this.setForm();
    console.table(this.ulValues);

  }
  //////////
  ///
  validatorNameNotExists(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }
      console.log("Värde" + value);
      let nameNotExists: boolean;
      this.service.testUlValuesNameExists(value).subscribe(_ => {
        nameNotExists = _;
      });
      
      return nameNotExists ? { nameExists: true } : null;
    }
  }
  //////////
  ///
  private setForm() {
    this.editForm.get("namn").setValue(this.name);
  }
  //////////
  ///
  public onSubmit() {
    this.submitted = true;
    //Om formuläret ok
    if (this.editForm.valid) {
      let ulValues: Test_UndersokningsvardenDto = {
        namn: this.editForm.get("namn")?.value ?? "",
        vardenJson: this.ulValues,
        id: this.id
      };

      this.service.saveTestUlValues(ulValues).subscribe({
        next: (result: any) => {
          this.modalRef.close();
        },
        error: (errorResponse: any) => {
        }
      });
    }
    //TODO: Lägg till service för redigering
    //let echovars: Array<string> = [];
    //this.variablesByGroupType2.forEach(group => {
    //  group.sysEchoVariables.forEach(variable => {
    //    if (this.editForm.get(["var_" + variable.id.toString()]).value == true && !echovars.includes(variable.id.toString()))
    //      echovars.push(variable.id.toString());
    //  });
    //});

    //let variabler: string = echovars.toString();
    //console.log("Valda variabler" + variabler);
    //const patient: PatientDto = {
    //  id: this.patientId > 0 ? this.patientId : 0,
    //  personalIdentityNumber: this.editForm.get("pnr")?.value ?? "",
    //  name: this.editForm.get("namn")?.value ?? "",
    //  lastname: this.editForm.get("enamn")?.value ?? "",
    //  ePost: this.editForm.get("epost")?.value ?? "",
    //  phone: this.editForm.get("telefon")?.value ?? "",
    //  genderId: this.editForm.get("kon")?.value ?? "",
    //  age: this.editForm.get("alder")?.value ?? ""
    //};

    //console.log("remissid" + this.remissId + "patientId" + this.patientId);
    //const remiss: RemittanceOnlyDto = {
    //  id: this.remissId > 0 ? this.remissId : 0,
    //  patientId: this.id > 0 ? this.plannedExamination.remiss.patientId : 0,
    //  remittanceClinicId: this.id > 0 ? this.plannedExamination.remiss.remittanceClinicId : 1,
    //  remittanceDoctorId: this.id > 0 ? this.plannedExamination.remiss.remittanceDoctorId : 1,
    //  active: this.id > 0 ? this.plannedExamination.remiss.active : true,
    //  remittanceStatusId: this.id > 0 ? this.plannedExamination.remiss.remittanceStatusId : 1,
    //  healthProblems: this.editForm.get("halsoproblem")?.value
    //};
    //const undersokning: PlannedExaminationDto = {
    //  id: this.id > 0 ? this.plannedExamination.plannedexamination.id : 0,
    //  remittanceClinicId: this.id > 0 ? this.plannedExamination.plannedexamination.remittanceClinicId : 0,
    //  remittanceDoctorId: this.id > 0 ? this.plannedExamination.plannedexamination.remittanceDoctorId : 0,
    //  remittanceId: this.id > 0 ? this.plannedExamination.plannedexamination.remittanceId : 0,
    //  weight: this.editForm.get("vikt")?.value ?? "",
    //  length: this.editForm.get("langd")?.value ?? "",
    //  active: this.id > 0 ? this.plannedExamination.plannedexamination.active : true,
    //  sysInfMedicalExaminationTypeId: this.editForm.get("uTyp")?.value ?? null,
    //  valdaVarJson: variabler
    //};
    //const helaundersokning: PatientRemissUndersokning = {
    //  patient: patient,
    //  remiss: remiss,
    //  plannedexamination: undersokning
    //}
    //console.table(helaundersokning);
    //this.uService.savePlannedMedicalExamination(this.id, helaundersokning).subscribe({
    //  next: () => {
    //    this.modalRef.close();
    //  },
    //  error: (errorResponse) => {
    //    //  this.errorMessage = errorResponse;
    //  }
    //});

  }
  ////////////
  /////
  onCancel(): void {
    this.modalRef.dismiss();
  }
  ////////////
  /////
  //changeTyp() {
  //  this.editForm.get("variantId")?.reset();
  //  this.setVariants();
  //  //if (!(this.id > 0))
  //  this.fetchVariables();
  //}
  //////////
  ///
  //fetchVariables() {
  //  //Ladda bägge listvyerna direkt och toggla sedan mellan dessa?
  //  this.variableService.getAllEchoVariablesByGroup(2).subscribe(
  //    (res: VariablesByGroupsDto[]) => {
  //      this.variablesByGroupType2 = res.filter(f => f.echoGroupTypeId == 2);
  //      this.variablesByGroupType2.forEach(group => {
  //        group.sysEchoVariables.forEach((variable) => {
  //          this.editForm.addControl("var_" + variable.id.toString(), new FormControl(false));
  //        });
  //      });
  //    });
  //}
}
@Injectable({
  providedIn: "any"
})
export class AddTestUndesokningsvardenModalService {
  constructor(private modalService: NgbModal) { }
  //TODO: Ändra Observable<any> till rätt modell
  public show(id: number, namn: string, values: UlResult[]): Observable<any> {
    const modalRef = this.modalService.open(AddTestUndesokningsvardenModalComponent, { backdrop: "static", size: "xl" });
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.name = namn;
    modalRef.componentInstance.ulValues = values;
    return modalRef.closed;
  }
}
export class UlValues {
  variableId: number;
  value: string;
}


import { Component, ElementRef, OnInit, ChangeDetectorRef, AfterContentChecked, OnChanges } from '@angular/core';
import { EditExaminationModalService } from '../components/add-examination-modal/add-examination-modal.component';
import { PatientRemissUndersokning, PlannedExaminationDto, Test_Undersokningsvarden, Test_UndersokningsvardenDto, UlResult, UndersokningService } from '../../../core/services/undersokning.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, Subscription, catchError, groupBy, interval, map, mergeMap, of, tap, toArray } from 'rxjs';
import { VariablesService } from '../../../core/services/variabler.service';
import { faBars, faInfoCircle, faExclamationTriangle, faEdit, faTrash, faAtom } from "@fortawesome/free-solid-svg-icons";
import { ViewEchoVariableDto } from '../../../model/undersokningsvarianter/view-echo-variable-dto';
import { CalculationService, Formel, Grunddata } from '../../../core/services/calculation.service';
import { AddTestUndesokningsvardenModalService, UlValues } from '../components/add-test-undesokningsvarden-modal/add-test-undesokningsvarden-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { EditVariableFormulaModalService } from '../../settings/components/formula-echovariable-modal/formula-echovariable-modal.component';
import { ToastrService } from 'ngx-toastr';
import { EditVariableModalService, SelectedMeasuremenMethod } from '../../settings/components/add-echovariable-modal/add-echovariable-modal.component';
import { SysEchoGroupDto } from '../../../model/undersokningsvarianter/sys-echo-group-dto';
import { RegEchoVariantDto } from '../../../model/undersokningsvarianter/reg-echo-variant-dto';
import { UndersokningsVariantService } from '../../../core/services/undersokningsvariant.service';
import { AlertMessageItem, AlertMessageType } from '../../../model/alert-message-item';
import { EchoEditKavldataModalModalService } from '../components/echo-edit-kavldata-modal/echo-edit-kavldata-modal.component';

@Component({
  selector: 'echo-testbadd',
  templateUrl: './echo-testbadd.component.html',
  styleUrls: ['./echo-testbadd.component.scss']
})
export class EchoTestbaddComponent implements OnInit, AfterContentChecked {
  $subscriptions: Subscription[] = [];
  errorMessage = '';
  examinationId = 0;
  examination!: PatientRemissUndersokning;
  plannedexamination!: PlannedExaminationDto;
  selectListUlValues!: Test_UndersokningsvardenDto[];
  currentSelectedSavedUl!: Test_UndersokningsvardenDto;
  varianter: Observable<RegEchoVariantDto[]> = null!;
  showMorInfoPatient: boolean = false;
  ulValuesImported: boolean = false;
  patientGrunddata: Grunddata;
  showSeletListUlValues: boolean = false;
  //anyValues: boolean = false;
  valdaVarJson: string = "";
  typeSelectLists: Typselectlist[] = [];
  allValueTypes: number[] = [];
  variables: ViewEchoVariableDto[] = null!;
  selectedValgrupp: number;
  statusGrupper: StatusGrupp[] = []
  variabler: ViewEchoVariableDto[] = [];
  variantsIds: string;
  varningar: WarningSymbol[] = [
    //Visa ingen symbol
    { id: 0, iconClass: "", colorClass: "", graderingsId: [] },
    { id: 1, iconClass: "fas fa-exclamation-triangle", colorClass: "text-danger", graderingsId: [] },
    { id: 2, iconClass: "fas fa-exclamation-triangle", colorClass: "text-danger", graderingsId: [1, 2, 6, 8] }];
  //Ikoner
  faBars = faBars;
  faInfoCircle = faInfoCircle;
  faExclamationTriangle = faExclamationTriangle;
  faEdit = faEdit;
  faTrash = faTrash;
  constructor(
    private uService: UndersokningService,
    private examinationmodal: EditExaminationModalService,
    private route: ActivatedRoute,
    private variableService: VariablesService,
    private calcService: CalculationService,
    private addUndersokningsVardenModal: AddTestUndesokningsvardenModalService,
    private editFormulaModal: EditVariableFormulaModalService,
    private toastr: ToastrService,
    private editVariableModal: EditVariableModalService,
    private changeDetector: ChangeDetectorRef,
    private variantService: UndersokningsVariantService,
    private kvalValuesModal: EchoEditKavldataModalModalService
  ) { }

  private _editForm: FormGroup = new FormGroup({
    textTypeEdit: new FormControl(false),
    viewVariables: new FormControl(),
    editAllaVariabler: new FormControl(false),
    sortVariabler: new FormControl(false),
    showJustVarningarVariabler: new FormControl(false),
    selectedSavedUlValues: new FormControl(0),
    showAllaVariabler: new FormControl(false)
  });
  get editForm(): FormGroup { return this._editForm; }
  get textTypeEdit() { return this.editForm.get("textTypeEdit"); }
  get viewVariables() { return this.editForm.get("viewVariables"); }
  get editAllaVariabler() { return this.editForm.get("editAllaVariabler"); }
  get showAllaVariabler() { return this.editForm.get("showAllaVariabler"); }
  get sortVariabler() { return this.editForm.get("sortVariabler"); }
  get showJustVarningarVariabler() { return this.editForm.get("showJustVarningarVariabler"); }


  //////////
  ///
  ngOnInit(): void {

    this.setSelectListULExaminations();
    this.getVariabler();
    this.$subscriptions.push(
      this.route.paramMap.subscribe(result => {
        this.examinationId = Number(result.get("id"));
        this.getAllValuetypes();
        this.getData();
        this.getValuesFromUl();
        this.sortVariables();
      })
    );
  }
  //////////
  ///
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  //////////
  ///
  ngOnDestroy(): void {
    this.$subscriptions.forEach(_ => _.unsubscribe());
  }
  //////////
  ///
  fetchVariables() {
    this.variableService.getAllVariablesForExamination(this.valdaVarJson).subscribe(
      (res: ViewEchoVariableDto[]) => {
        //this.variables = this.variables.map((v: ViewEchoVariableDto) => {
        //  if (res.some((r: ViewEchoVariableDto) => r.id === v.id)) {
        //    const updatedVariable = res.find((r: ViewEchoVariableDto) => r.id === v.id);
        //    v.warningSymbol = updatedVariable.warningSymbol;
        //    v.currentValue = updatedVariable.currentValue;
        //    v.ulValue = updatedVariable.ulValue;
        //    v.format = updatedVariable.format;
        //    v.typeSelectList = updatedVariable.typeSelectList;
            
        //  if (v.calculationJson?.intervalltyp == 1) {
        //    v.calculationJson?.formler.forEach(f => {
        //      if (f.formel)
        //        this.calculateFormula(f);
        //    });
        //  }
        //    return v;
        //  } else {
        //    return v;
        //  }

        //});
        //TODO: Om vi ska ha med värden från UL som inte är planerade måste dom läggas till här
        this.variables = res.map((v, index) => {
          this.groupAndSortVariables(v);
          v.warningSymbol = "";
          v.currentValue = "";
          v.ulValue = "";
          v.format = v.format.replace('.', ',').replace(/#/g, 'X');
          if (v.valueTypeId > 0 && v.valueTypeId) {
            v.typeSelectList = this.typeSelectLists.find(_ => _.typeid == v.valueTypeId)?.list ?? [];
          }
          this.editForm.addControl("var_table" + v.id.toString() + "_text", new FormControl());
          return v;
        });
        
      
    //this.variables = res.map((v, index) => {
    //  const existingVariable = this.variables.find((existingV) => existingV.id === v.id);
    //  if (existingVariable) {
    //    //existingVariable.groupAndSortVariables(v);
    //    existingVariable.warningSymbol = "";
    //    existingVariable.currentValue = "";
    //    existingVariable.ulValue = "";
    //    existingVariable.format = v.format.replace('.', ',').replace(/#/g, 'X');
    //    if (v.valueTypeId > 0 && v.valueTypeId) {
    //      existingVariable.typeSelectList = this.typeSelectLists.find(_ => _.typeid == v.valueTypeId)?.list ?? [];
    //    }
    //    return existingVariable;
    //  } else {
    //    //v.groupAndSortVariables(v);
    //    v.warningSymbol = "";
    //    v.currentValue = "";
    //    v.ulValue = "";
    //    v.format = v.format.replace('.', ',').replace(/#/g, 'X');
    //    if (v.valueTypeId > 0 && v.valueTypeId) {
    //      v.typeSelectList = this.typeSelectLists.find(_ => _.typeid == v.valueTypeId)?.list ?? [];
    //    }
    //    this.editForm.addControl("var_table" + v.id.toString() + "_text", new FormControl());
    //    return v;
    //  }
    //});
    res.forEach(v => {
      if (v.calculationJson?.intervalltyp == 1) {
        v.calculationJson?.formler.forEach(f => {
          if (f.formel)
            this.calculateFormula(f);
        });
      }
    });
      });
  //});
  }
  groupVariablesStatus(v: ViewEchoVariableDto[]) {
    this.$subscriptions.push(of(...v).pipe(groupBy((item) => item.status ?? 1),
      mergeMap((group) => group.pipe(toArray())),
      tap((response: any) => console.log(response)),
      toArray()).subscribe((groupedItems) => {
        let variables = groupedItems.map(v => {
          v.warningSymbol = "";
          v.currentValue = "";
          v.ulValue = "";
          v.format = v.format.replace('.', ',');
          v.format = v.format.replace(/#/g, 'X');
          if (v.valueTypeId > 0 && v.valueTypeId) {
            v.typeSelectList = this.typeSelectLists.filter(_ => _.typeid == v.valueTypeId)[0]?.list ?? [];
          }
          this.editForm.addControl("var_table" + v.id.toString() + "_text", new FormControl());
          //v.sort((a, b) => {
          //  if (a.currentValue == null || a.currentValue == "")
          //    return -1;
          //  //if (b.currentValue == null || b.currentValue == "")
          //  //  return -1;
          //  if (a.warningSymbol < b.warningSymbol)
          //    return 1;
          //  //if (a.warningSymbol > b.warningSymbol)
          //  //  return -1;
          //  if (a.currentValue != null && a.currentValue != "")
          //    return 1;
          //  //if (a.currentValue < b.currentValue)
          //  //  return -1;
          //  return 0
          //})
        })
      }))
  }
  //////////
  ///
  openEditExamination() {
    const modalRef = this.examinationmodal.show(this.examinationId);
    this.$subscriptions.push(
      modalRef.subscribe({
        next: () => {
          //Hämta datan på nytt efter redigering
          this.getData();
        },
        error: (error) => {
          //this.toastr.error(error.error);
        }
      })
    );
  }
  //////////
  ///
  getData() {
    this.uService.getPlannedExamination(this.examinationId).subscribe(_ => {
      this.examination = _;
      this.patientGrunddata = { ålder: _.patient.age.toString(), vikt: _.remiss.weight.toString(), längd: _.remiss.height.toString(), kon: _.patient.genderId }
      this.valdaVarJson = _.plannedexamination.valdaVarJson;
      this.variantsIds = _.plannedexamination.variantsIds;
      this.fetchVariables();
      this.getVarianterAndInfo();
    });
  }
  getVarianterAndInfo() {
    let variants: Number[] = this.variantsIds.split(",").map(_ => { return Number(_) });
    console.log("Varianter" + variants);
    this.varianter = this.variantService.examinationTypesList$.pipe(map(_ => _.filter(item => variants.includes(item.id), catchError(err => {
      return EMPTY;
    }))))
  }
  //////////
  ///
  checkIfAnyInputValues(): boolean {
    return this.variables?.filter(_ => _.currentValue != "" && _.currentValue != null).length > 0;
  }
  //////////
  ///
  getVariabler() {
    this.variableService.getEchoVariablesAndGroup().subscribe(_ => {
      this.variables = _;
    });
  }
  //////////
  ///
  showHideEdit(variable: ViewEchoVariableDto, show: boolean) {
    variable.showEdit = show;
    this.variables.find(_ => _.id != variable.id).showEdit = false;

    //TODO: Fortsätt här med att sätta fokus på input i tabell och lista
  }
  //////////
  ///
  showHideEditTextVariable(variable: ViewEchoVariableDto, show: boolean) {
    variable.showEditText = show;
  }
  //////////
  ///
  getValuesFromUl() {
    this.ulValuesImported = true;
    this.variables.map(v => {
      v.ulValue = this.currentSelectedSavedUl?.vardenJson.filter(_ => _.id == v.id)[0]?.value ?? "";
      v.currentValue = this.currentSelectedSavedUl?.vardenJson.filter(_ => _.id == v.id)[0]?.value ?? "";
      v.calculationJson.formler = v.calculationJson.formler ?? null;
      v.measurementMethods = this.currentSelectedSavedUl?.vardenJson.filter(_ => _.id == v.id)[0]?.measurementMethods ?? [];
      v.validateInput = true;
      console.table(v.calculationJson.formler);
      //Stäng alla
      v.showEdit = false;
    });
  }
  openKvalModal() {
    const mRef = this.kvalValuesModal.show(this.variables);
    this.$subscriptions.push(
      mRef.subscribe({
        next: (data) => {
          //Uppdatera värden som blivit ändrade i modalen
          data.map(_ => {
            this.variables.find(v => v.id == _.id).currentValue = _.currentValue;
            this.graderaValue(_, _.currentValue);
          });
        }
      })
    );
  }
  //////////
  ///
  showEdit(event: any, variable: ViewEchoVariableDto, grupp: VariabelGrupper) {
    //Om alla inte ska redigeras så ska dom icke aktuella variablerna stängas
    if (this.editForm.get("editAllaVariabler").value == false) {
      this.variables.filter(_ => _.id != event.variableId).forEach(_ => _.showEdit = false);
      variable.showEdit = event.show;
    }
    else {
      this.variables.forEach(_ => _.showEdit = true);
    }
  }
  //////////
  ///

  tabToNextVariable(event: any) {
    //Stäng dom icke aktuella variablerna
    this.variables.find(_ => _.id != event.id).showEdit = false;
    //Öppna nästa
    this.variables[event.vindex + 1].showEdit = true;
  }
  //////////
  ///
  showVariableValuesChanged(event: any, variable: ViewEchoVariableDto, grupp: VariabelGrupper) {
    variable = event.variable;
    variable.currentValue = event.value;

    this.variableChangeInput(variable, event.value);
 
  }
  //////////
  ///
  submitForm() {

  }
  //////////
  ///
  variableChangeInput(variable?: ViewEchoVariableDto, value?: string) {
    if (value) {
      variable.currentValue = value;
      variable.validationError = "";
      this.graderaValue(variable, value);
      this.sortVariables();
    }
  }
  //////////
  ///
  tabba() {
    this.variables[0].showEdit = true;
  }
  //////////
  ///
  graderingtextChange(variable: ViewEchoVariableDto, text: string) {
    variable.showEdit = false;
    variable.graderingText = text;
    variable.showEditText = false;
  }
  //sortVariables() {
  //  // Sort variables by status in ascending order (1-3)
  //  this.variables = this.variables.sort((a, b) => {
  //    return a.status - b.status;
  //  });
  //}
  //sortVariables() {
  //  // Sortering 1. Toma värden 2. Varningar 3. Ifyllda värden
  //  //-1 Visas överst
  //  this.variables.sort((a, b) => {
      
      
  //    //if (a.status == 1)
  //    //  return -1; 
  //    //if (a.status == 2)
  //    //  return 0;
  //    //if (a.status == 3)
  //    //  return 1;

  //    if (a.currentValue == null || a.currentValue == "")
  //      return -1;
  //    //if (b.currentValue == null || b.currentValue == "")
  //    //  return -1;
  //    if (a.warningSymbol != null && a.warningSymbol != "")
  //      return 0;
  //    //if (a.warningSymbol > b.warningSymbol
  //    //  return -1;
  //    if (a.currentValue != null && a.currentValue != "" && (a.warningSymbol == null || a.warningSymbol != ""))
  //      return 1;
  //    //if (a.currentValue < b.currentValue)
  //    //  return -1;
  //  });
  //}
  sortVariables() {
    this.variables.sort((a, b) => {
      if (a.currentValue == null || a.currentValue == "")
        return -1;
      if (b.currentValue == null || b.currentValue == "")
        return 1;
      if (a.warningSymbol != null && a.warningSymbol != "")
        return -1;
      if (b.warningSymbol != null && b.warningSymbol != "")
        return 1;
      if (a.currentValue != null && a.currentValue != "")
        return -1;
      if (b.currentValue != null && b.currentValue != "")
        return 1;
      return 0;
    });
  }
  //////////
  ///
  //firstMatchWarning(variable: ViewEchoVariableDto) {
  //  let variablerMedVarningar: ViewEchoVariableDto[] = this.variables.filter(_ => _.warningSymbol != "");
  //  if (variablerMedVarningar.findIndex(_ => _.id == variable.id) == 0)
  //    return true;
  //  else
  //    return false;
  //}
  ////////////
  /////
  //lastMatchWarning(variable: ViewEchoVariableDto) {
  //  let variablerMedVarningar: ViewEchoVariableDto[] = this.variables.filter(_ => _.warningSymbol != "");
  //  let variablerMedVarningarCount: number = variablerMedVarningar.filter(_ => _.warningSymbol != "").length - 1;
  //  if (variablerMedVarningar.findIndex(_ => _.id == variable.id) == variablerMedVarningarCount)
  //    return true;
  //  else
  //    return false;
  //}
  //////////
  ///
  graderaValue(variable: ViewEchoVariableDto, value: string): string | null {
    let isFlode: boolean = variable.unitId == 5 || variable.unitId == 13 ? true : false;
    if (variable.calculationJson?.formler?.length > 0) {
      let formler: Formel[] = [];
      if (variable.calculationJson.kontyp == 0)
        formler = variable.calculationJson.formler?.filter(_ => _ != null);
      else
        formler = variable.calculationJson?.formler?.filter(_ => _?.kon == this.patientGrunddata.kon);
      if (formler != null && value != null) {
        this.calcService.gradera(formler, this.patientGrunddata, variable.calculationJson.format, isFlode, value).subscribe(data => {
          variable.graderingText = data.value.graderingstext.replaceAll("{variabel}", variable.shortName);
          variable.graderingstyp = data.value.graderingstyp;
          this.setSymbol(variable, variable.graderingstyp);
          //Om det är formler
          //Om det är en formel ska det beräknade värdet visas.
          if (variable.calculationJson.intervalltyp == 1 && formler.length > 0) {
            formler.map(e => {
              data.value.formler.map(_ => { if (_?.graderingstyp == e?.graderingstyp) e.beraknat = _.beraknat })
              //if (this.editForm.get("sortVariabler").value == true)
            });
          }
          //Sätt graderingstext på formcontrollen för texten på graderingen.
          this.editForm.get("var_table" + variable.id + "_text").patchValue(variable.graderingText);
        });
      }
      else {
        return null;
      }
    }
  }
  //////////
  ///
  getAllValuetypes() {
    this.variableService.getInfoSysInfEchoValueType().subscribe(_ => {
      _.map(v => {
        this.allValueTypes.push(v.id);
        this.variableService.getInfEchoValueTypeList(v.id).subscribe(g => {

          let optionlist: SelectLista[] = [];
          g.map(t => {
            optionlist.push({
              value: t.value,
              text: t.text,
              description: t.description
            });
          });
          let type: Typselectlist = {
            typeid: v.id,
            list: optionlist
          };

          this.typeSelectLists.push(type);
        });
      })
    });
  }
  //////////
  ///
  setSelectListULExaminations() {
    this.uService.getUlValuesTest().subscribe(_ => this.selectListUlValues = _);
  }
  //////////
  ///
  changeSelectedMatningFromUl(selectedId: number) {
    this.resetValues();
    this.currentSelectedSavedUl = this.selectListUlValues.filter(v => v.id == selectedId)[0];
    this.currentSelectedSavedUl.vardenJson.map(_ => {
      const variable = this.variables.find(v => v.id === _.id);
      if (variable) {
        variable.currentValue = _.value;
        variable.measurementMethods = _.measurementMethods;
        this.graderaValue(variable, _.value);
      }
    });
    this.openKvalModal();

  }
  //////////
  ///
  setSymbol(variable: ViewEchoVariableDto, graderingstyp: number) {
    if (variable.currentValue != "" && variable.currentValue != null) {
      variable.status = 2;
      variable.warningSymbol = this.varningar.filter(_ => { if (_.graderingsId.find(g => g == graderingstyp)) return _ })[0]?.iconClass ?? "";
      variable.warningSymbolColor = this.varningar.filter(_ => { if (_.graderingsId.find(g => g == graderingstyp)) return _ })[0]?.colorClass ?? "";
      variable.warningText = variable.graderingText ?? "";
    }
    //if (this.editForm.get("sortVariabler").value == true)
    this.sortVariables();
  }
  //////////
  ///
  openAddUndersokningsvarden(id: number, namn: string): void {
    let values: UlResult[] = [];
    this.variables.map(v => {
      let methods: SelectedMeasuremenMethod[] = [];
      v.measurementMethods?.map(m => methods.push({ id: m.id, value: m.value, sortorder: m.sortorder, selected: m.selected, name: m.name }));
      values.push({ id: v.id, variableName: v.shortName, value: v.currentValue ?? "", unit: v.unitId != 16 ? v.unit : '', measurementMethods: methods });
    });

    const modalRef = this.addUndersokningsVardenModal.show(id, namn, values);
    //this.$subscriptions.push(
    //  modalRef.subscribe({
    //    next: () => {
    //      this.setSelectListULExaminations();
    //    }
    //  })
    //);
  }
  
  //////////
  ///
  resetValues() {
    //  //Nollställ alla värden
    this.variables.map(v => {
      console.log("Variabelnamn: " + v.shortName);
      v.currentValue = "";
      v.ulValue = "";
      v.warningText = "";
      v.warningSymbol = "";
      v.graderingText = "";
      v.validationError = "";
      this.setSymbol(v, 0);
    });
  }
  //////////
  ///
  toggleSelectUlValues(show: boolean) {
    if (!show) {
      this.resetValues();
    }
  }
  //////////
  ///
  /*Edit Graderingar Modal*/
  editGraderingar(id: number, shortname: string, format: string, variabel: ViewEchoVariableDto) {
    //Formatera tillbaka formatet.
    format = format.replace(/X/g, '#');
    const modalRef = this.editFormulaModal.show(id, shortname, format, this.patientGrunddata);
    this.$subscriptions.push(
      modalRef.subscribe({
        next: () => {
          this.toastr.success("Graderingar är sparade.", "", { positionClass: 'toast-top-center' });
          //TODO: Gör anrop för att hämta gradering på variablen
          //TODO: Placera in värdet på rätt ställe på variabeln. 
          this.getGraderingarVariabel(id, variabel);
        }
      })
    );
  }
  //////////
  ///
  openEditShowVariable(id: number, variableName: string): void {
    const modalRef = this.editVariableModal.show(id, variableName);
    this.$subscriptions.push(
      modalRef.subscribe(() => {
        //TODO: Uppdatera info om variabeln.
      })
    );
  }

  //////////
  ///
  //Hämta graderingar på uppdaterad variabel.
  getGraderingarVariabel(id: number, variabel: ViewEchoVariableDto) {
    this.calcService.getVariabelGraderingar(id).subscribe(_ => {
      variabel.calculationJson = _;
      this.groupAndSortVariables(variabel);
      this.graderaValue(variabel, variabel.currentValue);
    });
  }
  //////////
  ///
  //Gruppera variabler och sortera på kön
  groupAndSortVariables(v: ViewEchoVariableDto) {
    if (v.calculationJson.formler) {
      this.$subscriptions.push(
        of(...v.calculationJson?.formler)
          .pipe(
            groupBy((item) => item.kon ?? null),
            mergeMap((group) => group.pipe(toArray())),
            tap((response: any) => console.log(response)),
            toArray()
          ).subscribe((groupedItems) => {
            //Nollställ. TODO: Snygga till detta!!
            v.calculationJson?.formler?.forEach(_ => {
              _.graderingsText = _.graderingsText.replaceAll("{variabel}", v.shortName)
            });
            //Slå ihop grupperingen av kön till formel-modellen.
            let kongrupper = groupedItems.map(_ => _.sort((a, b) => (a.graderingstyp > b.graderingstyp) ? 1 : -1));
            if (kongrupper.length > 0) {
              v.calculationJson.formler = kongrupper[0].concat(kongrupper[1]);
              //Ta bort null
              v.calculationJson.formler = v.calculationJson.formler.filter(_ => _ != null);
            }
            else
              v.calculationJson.formler = kongrupper;
            if (v.calculationJson.format != "" || v.calculationJson.format != null)
              v.calculationJson.format = v.calculationJson.format.replace(/#/g, 'X');
          }
          ));
    }
  }
  calculateFormula(formel: Formel) {
    this.calcService.getCalculatedFormulaLinnea(formel, this.patientGrunddata).subscribe(_ => {
      formel.beraknat = _;
    });
  }
  //////////
  ///
  toggleEditAllVariabler() {
    if (this.editForm.get("editAllaVariabler").value)
      this.variables.forEach(_ => _.showEdit = true);
    else
      this.variables.forEach(_ => _.showEdit = false);
  }
  //////////
  ///
  toggleShowAllVariabler() {
    if (this.editForm.get("showAllaVariabler").value) {
      this.variables.map(_ => {
        if (_.currentValue == null || _.currentValue == '')
          _.show = true;
        _.status = 3;
      });
      this.sortVariables();
    }
    else {
      this.variables.map(_ => _.show = false);
    }
  }
  //////////
  ///
  deleteExaminationValues(id: number) {
    this.uService.deleteUlValuesTest(id).subscribe({
      next: () => {
        this.setSelectListULExaminations();
      },
      error: (errorResponse) => {
        //  this.errorMessage = errorResponse;
      },
    });
  }
  //////////
  ///
  openMetodbeskrivning(url: string) {
    window.open(url, "_blank");
  }
}
export type Typselectlist = {
  typeid: number;
  list: SelectLista[];
}
export type SelectLista = {
  value: any;
  text: string;
  description: string;
}
export type WarningSymbol = {
  id: number;
  iconClass: string;
  colorClass: string;
  graderingsId?: number[] | [];
}
export type VariabelGrupper = {
  id: number;
  namn: string;
  hasSelectedVariables?: boolean;
  hasUnSelectedVariablesWithValue?: boolean;
  variables?: ViewEchoVariableDto[];
}
export type StatusGrupp = {
  id?: number;
  decription?: string;
  variables?: ViewEchoVariableDto[];
}

<form [formGroup]="variableForm">
  <!--<div *ngIf="(variable.selected || (variable.currentValue != null && variable.currentValue != '')) && (variable.valueTypeId > 0 && variable.valueTypeId < 7)" class="row">-->
  <div *ngIf="(variable.valueTypeId > 0 && variable.valueTypeId < 7)" class="row">

    <!--Om det är listvisningen ska en symbol visas istället.-->
    <!--<a *ngIf="style == 2" (click)="showHideEdit(variable.showEdit = !variable.showEdit)">{{ variable.showEdit ? "Stäng": "Redigera värde" }}</a>-->
    <!--Kvalitativa val-->
    <select class=" form-select-sm form-select" formControlName="valgruppOptionsModal" tabindex="{{setFocus ? 1 : 0}}" (keydown)="onKeyPressedOption($event)" (change)="variableChangeInput($event.target.value)" [ngClass]="variable.selected ? 'border border-5': ''">
      <option value="null">Välj alternativ</option>
      <option *ngFor="let option of variable.typeSelectList" [value]="option.value">{{option.value}} | {{option.text}}</option>
    </select>
  </div>
  <!--<div *ngIf="var_currentValue.errors.required">Obligatoriskt fält</div>-->
  <!--<small *ngIf="cValue?.errors?.['max']" class="text-danger">Överstigit maxvärde</small>
  <small *ngIf="cValue?.errors?.['min']" class="text-danger">Under minvärde</small>
  <div *ngIf="variable.valueTypeId == null" class="col-6">
    <small class="text-danger pointer" (click)="openEditVariabel()" [ngbTooltip]="'Lägg till mätmetod'">Variabeln saknar metod för gradering</small>
  </div>-->
</form>

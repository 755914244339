<form [formGroup]="editForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      Kvalitativa bedömningar
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngFor="let variable of variables; let i = index;">
      <!--<ng-container *ngIf="(variable.selected || (variable.currentValue != null && variable.currentValue != '')) && (variable.valueTypeId > 0 && variable.valueTypeId < 7)">-->
      <ng-container *ngIf="(variable.valueTypeId > 0 && variable.valueTypeId < 7)">

        <div class="row pt-2 align-items-center">
          <div class="col-2">
            {{variable.shortName}}
          </div>
          <div class="col-8">
            <option-value-examination [showEditVariable]="true"
                                      [currentValue]="variable.currentValue"
                                      [variable]="variable"
                                      [variableId]="variable.id"
                                      [typeSelectList]="variable.typeSelectList"
                                      (variableValuesChanged)="showVariableValuesChanged($event, variable)"
                                      [setFocus]="i == 0"></option-value-examination>
            <!--(tabToNextVariable)="tabToNextVariable($event)"-->
          </div>
          <div class="col-2">
            <a href="/" target="_blank"><fa-icon [icon]="faInfoCircle" [ngbTooltip]="'Visa metodbeskrivning'"></fa-icon></a>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Stäng</button>
    <button type="submit" class="btn btn-primary save" [disabled]="!editForm.valid">Spara</button>
  </div>
</form>


<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-10">
      <h1 class="text-center">Hantera undersökningsvarianter för ekokardiografi</h1>
      <hr>
      <!--<ph-alert-message [messageItem]="alertMessage|async" [isSelfClosing]="true"></ph-alert-message>-->
      <form [formGroup]="examinationTypeForm" (ngSubmit)="submitForm()" autocomplete="off">
        <div class="row py-4">
          <div class="col-md-6">
            <div class="form-group">
              <label for="examinationTypeOption"><b>Redigera undersökningsvariant:</b></label>
              <select class="form-select" *ngIf="examinationTypesList | async" id="examinationTypeOption" (change)="getDataFromEchoType($event.target.value)" formControlName="examinationType">
                <!--<option *ngIf="examinationType.visible" [value]="examinationType.id">{{ex.variantName}} <span *ngIf="examinationType.default" [ngbTooltip]="'Skapa en ny undersökningsvariant och gör en kopia av denna, om du vill det.'">(Systemtyp)</span></option>-->
                <option value="0">Välj undersökningsvariant</option>
                <option *ngFor="let exType of examinationTypesList | async" [value]="exType.id">{{exType.variantName}}</option>
              </select>
              <!--<small class="form-text text-muted">Systemtyper är låsta. Du kan välja att skapa en ny undersökningsvariant och utgå från en systemtyp.</small>-->
            </div>
          </div>
          <div class="col-md-6 pt-4 d-flex justify-content-end">
            <button (click)="openAddExaminationTypeModal()" class="btn btn-primary text-dark">Ny undersökningsvariant <i class="fas fa-plus-circle"></i></button>
          </div>
        </div>
        <ph-loading-spinner *ngIf="!selectedExaminationTypeId && examinationTypeForm.value.examinationType != 0"></ph-loading-spinner>

        <div class="card" *ngIf="typeSelected">
          <div class="card-body">
            <input type="hidden" formControlName="id">
            <div class="form-group mt-4">
              <label for="variantName">Namn på undersökningsvarianten: </label>
              <input type="text" class="form-control" formControlName="variantName" placeholder="Namn">
              <div *ngIf="variantName.touched && variantName.errors" class="text-danger font-italic">
                <div *ngIf="variantName.errors.required">Fältet är obligatoriskt</div>
              </div>
            </div>
            <div class="form-group">
              <label for="variantDescription">Beskrivning av undersökningsvarianten: </label>
              <textarea type="text" formControlName="variantDescription" class="form-control" placeholder="Beskrivning av undersökningsvarianten"></textarea>
              <div *ngIf="variantDescription.touched && variantDescription.errors" class="text-danger font-italic">
                <div *ngIf="variantDescription.errors.required">Fältet är obligatoriskt</div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-check">
                    <label class="form-check-label" for="typeActiveFrom">
                      <input formControlName="typeActiveFrom" id="typeActiveFrom" class="form-check-input" type="checkbox">
                      Sätt giltighetstid start
                    </label>
                  </div>
                </div>
              </div>
              <div *ngIf="typeActiveFrom.value">
                <div class="col-md-3 text-right">
                  <label class="col-form-label" for="giltigFromDatum">
                    Giltighet från:
                  </label>
                </div>
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="text"
                           placeholder="Giltig från"
                           class="form-control"
                           formControlName="validFrom" #d="ngbDatepicker" ngbDatepicker>
                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-check">
                    <input formControlName="typeActiveTo" class="form-check-input" type="checkbox" id="typeActiveTo">
                    <label class="form-check-label" for="typeActiveTo">
                      Sätt giltighetstid slut:
                    </label>
                  </div>
                </div>
              </div>
              <div *ngIf="typeActiveTo.value">
                <div class="col-md-3 text-right">
                  <label class="col-form-label" for="giltigToDatum">
                    Giltighet till:
                  </label>
                </div>
                <div class="col-md-6">
                  <div class="input-group">
                    <input type="text"
                           placeholder="Giltig till"
                           name="giltigToDatum"
                           class="form-control"
                           formControlName="validTo" #d="ngbDatepicker" ngbDatepicker>
                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-2">
              <div class="col-md-3">
                <div class="form-group">
                  <div class="form-check">
                    <input formControlName="inaktiv" class="form-check-input" type="checkbox" id="inaktiv">
                    <label class="form-check-label" for="inaktiv">
                      Inaktivera variant
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-top row t-3 pt-2 pb-2 border-bottom">
              <div class="col-md-6">
                <h4>Variabler</h4>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <select class="form-select" (change)="showVyType($event)">
                  <option [value]="1">Undersökningsvyer</option>
                  <option [value]="2">Fysiologiska/Anatomiska vyer</option>
                </select>
              </div>
            </div>
            <div class="row" *ngIf="vyType == 1">
              <ng-container *ngIf="variablesByGroupType1.length === 0" class="text-center">
                <loading-spinner></loading-spinner>
                <i>Laddar variabler...</i>
              </ng-container>
              <ng-container *ngIf="variablesByGroupType1.length > 0">
                <ng-container *ngFor="let grupp of variablesByGroupType1">
                  <div class="col-lg-2 col-md-3 col-sm-4 pt-2">
                    <h6>{{grupp.echoGroup}}</h6>
                    <ng-container *ngFor="let variable of grupp.sysEchoVariables">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="var_{{variable.id}}" formControlName="var_{{variable.id}}" />
                        <label class="form-check-label" for="var_{{variable.id}}" [ngbTooltip]="variable.description">
                          <!--[class.font-weight-bold]="item.selected"-->
                          {{variable.shortName}}
                        </label>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="row" *ngIf="vyType == 2">
              <ng-container *ngIf="variablesByGroupType2.length === 0" class="text-center">
                <loading-spinner></loading-spinner>
                <i>Laddar variabler...</i>
              </ng-container>
              <ng-container *ngIf="variablesByGroupType2.length > 0">
                <ng-container *ngFor="let grupp of variablesByGroupType2">
                  <div class="col-lg-2 col-md-3 col-sm-4 pt-2">
                    <h6>{{grupp.echoGroup}}</h6>
                    <ng-container *ngFor="let variable of grupp.sysEchoVariables">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="var_{{variable.id}}" formControlName="var_{{variable.id}}" />
                        <label class="form-check-label" for="var_{{variable.id}}" [ngbTooltip]="variable.description">
                          <!--[class.font-weight-bold]="item.selected"-->
                          {{variable.shortName}}
                        </label>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="card-footer text-right">
            <button type="button" class="btn btn-secondary">Avbryt</button>
            <button type="submit" class="btn btn-primary">Spara</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

